import React, {useState, useContext} from 'react';
import { Link } from "react-router-dom";

import { SocketContext } from './context/socket';

const App = () => {
  const socket = useContext(SocketContext);

  const [ online, setonline ] = React.useState(0);

  setInterval(() => {
    socket.emit('getOnline');
  }, 1000);

  socket.on('roomOnline', setonline);

  return <>
    <Link to='/game'>Game link</Link>
    <div>Total online: <span>{ online }</span></div>
  </>;
};

export default App;