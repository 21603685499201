import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import CannonDebugger from 'cannon-es-debugger';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'stats-js';
import { GUI } from 'dat.gui';

import { SocketContext } from '../context/socket';

import React, { useEffect, useContext } from 'react';

import '../assets/css/style.css';
import backIMG from "../assets/img/back.png"
import play_zoneIMG from "../assets/img/play_zone.png"
import play_zone_sideIMG from "../assets/img/play_zone_side.png"
import texture1IMG from "../assets/img/1.png"
import texture2IMG from "../assets/img/2.png"
import texture3IMG from "../assets/img/3.png"


const Game = () => {
  const [ msg, setMsg ] = React.useState('');

  const socket = useContext(SocketContext);
  
  useEffect(() => {
    const gui = new GUI()
    const actFolder = gui.addFolder('Actions')
    let items = [];
    let sphereBodies = [];
    let DEBUG = false;
    let fixedTimeStep = 1.0 / 60.0;
    let maxSubSteps = 3;
    let lastTime;
    
    const headsTexture = new THREE.TextureLoader().load(backIMG);
    // const scratchTexture = new THREE.TextureLoader().load("../assets/img/scratch.png");
    const playZone = new THREE.TextureLoader().load(play_zoneIMG);
    
    const capsTexture = [
        new THREE.TextureLoader().load( texture1IMG ),
        new THREE.TextureLoader().load( texture2IMG ),
        new THREE.TextureLoader().load( texture3IMG ),
    ];
    const capsSkin = [
        new THREE.MeshPhongMaterial({
            map: capsTexture[0],
            shininess: 100,
        }),
        new THREE.MeshPhongMaterial({
            map: capsTexture[1],
            shininess: 100,
        }),
        new THREE.MeshPhongMaterial({
            map: capsTexture[2],
            shininess: 100,
        }),
    ];
    
    const capBacks = new THREE.MeshPhongMaterial({
        map: headsTexture,
        shininess: 100,
    });
    const capSide = new THREE.MeshPhongMaterial({ color: 0x959FA8, shininess: 100 });
    const planeSide = new THREE.MeshPhongMaterial({ color: 0xf7f7f7, shininess: 100 });
    
    // ######### CAMERA #########
    let aspectRatio = window.innerWidth / window.innerHeight;
    let camera = new THREE.PerspectiveCamera(75, aspectRatio, 1, 3000);
    camera.position.set(0, 1000, 1000);
    // ######### CAMERA #########
    
    
    // ######### WORLD #########
    let world = new CANNON.World();
    world.gravity.set(0, -200, 0);
    world.broadphase = new CANNON.NaiveBroadphase();
    world.broadphase.useBoundingBoxes = true;
    let solver = new CANNON.GSSolver();
    solver.iterations = 2;
    solver.tolerance = 0.1;
    world.solver = solver;
    world.quatNormalizeFast = true;
    // world.quatNormalizeSkip = 0;
    // world.defaultContactMaterial.contactEquationStiffness = 1e9;
    world.defaultContactMaterial.contactEquationRelaxation = 4;
    world.addBody(new CANNON.Body());
    // ######### WORLD #########
    
    
    // ######### SCENE #########
    let scene = new THREE.Scene();
    scene.background = new THREE.Color(0xFFFFFF);
    // ######### SCENE #########
    
    const debug = () => {
      DEBUG = !DEBUG
    };
    
    // ######### STATS #########
    let stats = new Stats();
    stats.showPanel( 0 );
    document.body.appendChild( stats.dom );
    // ######### STATS #########
    
    
    // ######### RENDERER #########
    let renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    // ######### RENDERER #########
    
    
    let controls = new OrbitControls( camera, renderer.domElement );
    controls.maxPolarAngle = Math.PI / 2.3;
    controls.maxDistance = 2500;
    controls.minDistance = 800;
    controls.enablePan = false;
    controls.rotateSpeed = .75;
    
    
    let cannonDebugger = new CannonDebugger(scene, world, {
      onInit(body, mesh) {
        mesh.visible = false
      },
      onUpdate(body, mesh) {
        mesh.visible = DEBUG
      }
    });
    
    
    // ######### PLANE #########
    let planeMaterial = new THREE.MeshPhongMaterial({
        map: playZone,
        shininess: 100,
    });
    let planeDownSideMaterial = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        shininess: 100,
    });
    
    let planeSideMaterial = [
        Math.PI / 2,
        -Math.PI / 2,
        Math.PI,
        Math.PI * 2,
    ].map(e=> {
        const playZoneSide = new THREE.TextureLoader().load(play_zone_sideIMG);
        playZoneSide.rotation = e;
        playZoneSide.center = new THREE.Vector2(0.5,0.5);
        let a = new THREE.MeshPhongMaterial({
            map: playZoneSide,
            transparent: true,
            shininess: 100,
        });
        return a;
    });
    
    const planeMaterials = [
        planeSideMaterial[0],
        planeSideMaterial[1],
        planeSideMaterial[2],
        planeSideMaterial[3],
        planeMaterial,
        planeDownSideMaterial,
        
    ];
    let geometry = new THREE.BoxGeometry( 1000, 1000, 300 );
    let plane = new THREE.Mesh( geometry, planeMaterials );
    plane.position.set( 0, 0, 0 );
    plane.rotation.x = - Math.PI * 0.5;
    plane.receiveShadow = true;
    scene.add( plane );
    let q = plane.quaternion;
    let p = plane.position;
    let v = plane.geometry.vertices;
    let planeBody = new CANNON.Body({
        mass: 0,
        shape: new CANNON.Box(new CANNON.Vec3(500, 500, 150)),
        quaternion: new CANNON.Quaternion(-q._x, q._y, q._z, q._w),
        position: new CANNON.Vec3(p.x, p.y, p.z)
    });      
    world.addBody(planeBody);
    // ######### PLANE #########
    
    // ######### PLANE BOX #########
    setTimeout(() => {
        [
            [[500, 50, 300], [0,450,550]],
            [[50, 500, 300], [550,450,0]],
            [[50, 500, 300], [-550,450,0]],
            [[500, 50, 300], [0,450,-550]],
            [[500, 500, 50], [0,800,0]],
        ].forEach((b) => {
            world.addBody(new CANNON.Body({
              mass: 0,
              shape: new CANNON.Box(new CANNON.Vec3(...b[0])),
              quaternion: new CANNON.Quaternion(-q._x, q._y, q._z, q._w),
              position: new CANNON.Vec3(...b[1])
          }))
        });
    }, 1);
    
    // ######### PLANE BOX #########
    
    
    const addItems = (
      itms = 1,
      emit = false,
      vel = rand(-700, -1000),
      rotX = rand(-3,3)/10,
      rotY = rand(-30,30)/10,
      ) => {
        if (emit) {
          socket.emit('play', {
            room,
            values: [itms, false, vel, rotX, rotY],
          });
        }
        for (let i = 0; i < itms; i++) {
            let geometry = new THREE.CylinderGeometry(100, 100, 4, 64);
            geometry.rotateX(Math.PI);
    
            const materials = [
                capSide,
                capsSkin[rand(0, 2)],
                capBacks,
            ];
            let sphere = new THREE.Mesh(geometry, materials);
            sphere.position.y = 550 + (i*6)
            sphere.position.x = 0
            sphere.position.z = 0
            // sphere.rotation.x = Math.PI / rot;
            scene.add(sphere);
            items.push(sphere);
          
            let q = sphere.quaternion;
            let p = sphere.position;
            let sphereBody = new CANNON.Body({
                mass: 1,
                position: new CANNON.Vec3(p.x, p.y, p.z),
                shape: new CANNON.Cylinder(100, 100, 5, 12),
                velocity: new CANNON.Vec3(0,vel,0),
            });
            let quatX = new CANNON.Quaternion();
            let quatY = new CANNON.Quaternion();
            quatX.setFromAxisAngle(new CANNON.Vec3(1,0,0), rotX);
            quatY.setFromAxisAngle(new CANNON.Vec3(0,1,0), rotY);
            let quaternion = quatY.mult(quatX);
            quaternion.normalize();
            sphereBody.quaternion = quaternion;
            world.addBody(sphereBody);
            sphereBodies.push(sphereBody);
        }
    }
    
    const rand = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    
    const render = () => {
        stats.begin();
        requestAnimationFrame(render);
        renderer.render(scene, camera);
    // if (sphereBodies[0]) {
    //   console.log(sphereBodies[0].quaternion);
    // }
        controls.update()
        renderer.toneMappingExposure = Math.pow( 0.8, 5.0 );
        renderer.shadowMap.enabled = true;
        stats.end();
    };
    
    const simloop = (time) => {
        cannonDebugger.update()
    
        requestAnimationFrame(simloop);
    
        if (lastTime !== undefined){
            let dt = (time - lastTime) / 1000;
            world.step(fixedTimeStep, dt*2, maxSubSteps);
            for (let i = 0; i < items.length; i++) {
                if (sphereBodies[i]) {
                    items[i].position.copy(sphereBodies[i].position);
                    items[i].quaternion.copy(sphereBodies[i].quaternion);
                }
            }
        }
        lastTime = time;
    }
    simloop();
    
    const clear = () => {
        items.forEach(e => scene.remove( e ));
        if (!DEBUG) {
            scene.children.forEach(e => {
                setTimeout(() => {
                    if (e.userData.debug) scene.remove(e)
                }, 1);
            });
        }
        world.bodies.splice(7, world.bodies.length-1);
        items = [];
        sphereBodies = [];
    }
    
    
    // window.addEventListener('resize', (e) => {
    //     let windowHalfX = window.innerWidth / 2;
    //     let windowHalfY = window.innerHeight / 2;
    //     camera.aspect = window.innerWidth / window.innerHeight;
    //     camera.updateProjectionMatrix();
    //     renderer.setSize(window.innerWidth, window.innerHeight);
    // });
    
    // ######### LIGHT #########
    const bulbGeometry = new THREE.SphereGeometry( 20, 16, 8 );
    let bulbLight = new THREE.PointLight( 0xFFFFFF, .3);
    let bulbMat = new THREE.MeshStandardMaterial( {});
    bulbLight.add( new THREE.Mesh( bulbGeometry, bulbMat ) );
    bulbLight.position.set( -10, 1000, 400 );
    bulbLight.castShadow = true;
    scene.add( bulbLight );
    scene.add(new THREE.AmbientLight(0xFFFFFF, 0.7));
    // ######### LIGHT #########
    
    render();
    
    // ######### REPLAY MODE #########
    if (location.hash) play(JSON.parse(atob(location.hash.replace('#', ''))));
    // ######### REPLAY MODE #########
    
    const addChatMessage = (msg) => {
      const item = document.createElement('li');
      item.textContent = msg;
      messages.appendChild(item);
      msg = document.getElementById('messages')
      msg.scrollTop = msg.scrollHeight;
    }
    
    let username = localStorage.getItem('username') || prompt('username', 'anon');
    let room = localStorage.getItem('room') || prompt('room', 'main');
    
    
    localStorage.setItem('username', username);
    localStorage.setItem('room', room);
    
    // document.getElementById('username').innerHTML = localStorage.getItem('username');
    // document.getElementById('room').innerHTML = localStorage.getItem('room');
    // ######### WS #########
    
    socket.on('message', (data) => {
      addChatMessage(data.msg);
    });
    
    socket.on('roomOnline', (data) => {
      document.getElementById('online').innerHTML = data;
    });
    
    setInterval(() => {
      socket.emit('getRoomOnline', room);
    }, 1000);
    
    socket.on('play', (msg) => {
      clear();
      addItems(...msg.values);
    });
    
    socket.emit('join', {
      name: username,
      room,
    });
    // ######### WS #########
    
    let playBtn = {
      debug,
      clear,
      add1: () => { addItems() },
      add2: () => { addItems(2) },
      add5: () => { addItems(5) },
      add10: () => { addItems(10) },
      add30: () => { addItems(30) },
      mp10: () => { addItems(10, true) },
    };
    
    actFolder.add(playBtn, 'debug')
    actFolder.add(playBtn, 'clear')
    actFolder.add(playBtn, 'add1')
    actFolder.add(playBtn, 'add2')
    actFolder.add(playBtn, 'add5')
    actFolder.add(playBtn, 'add10')
    actFolder.add(playBtn, 'add30')
    actFolder.add(playBtn, 'mp10')
    actFolder.open()
    
    // let deg = -21;
    // let realdeg = -21;
    // let degMax = 21;
    // let degAdd = 0.4;
    // let axx = setInterval(() => {
    //   deg += degAdd
    //   document.getElementsByClassName('bar_head_wrapper')[0].style.transform = `rotate(${realdeg}deg)`;
    //   if (deg >= degMax) degAdd = -0.4
    //   if (deg <= -degMax) degAdd = 0.4
    //   realdeg = deg + (2 * Math.sign(degAdd))
    // }, 10);
    
    // document.getElementById('hit').addEventListener('click', e => {
    //   let perc = 100 - (Math.abs(deg) * 100 / degMax);
    //   let vel = -300 - (1000 * perc / 100);
    //   console.log(Math.floor(perc), deg, realdeg, vel);
    //   addItems(10,true,vel);
    // });
}, []);

const changeNick = () => {
  localStorage.removeItem('username');
  localStorage.removeItem('room');
  location.reload();
}

const chatSend = () => {
  socket.emit('message', {
    fromUser: true,
    room,
    msg: msg,
  });
  setMsg('');
}

const msgHandler = (e) => {
  setMsg(e.target.value);
}
  return <div>
    <ul id="messages"></ul>
    <div id="form">
      <input id="chatField" autoComplete="off" value={msg} onChange={msgHandler}/>
      <button onClick={chatSend}>Send</button>
      <br/>
      <button id="changeNick" onClick={changeNick} >Change Nick and Room</button><br/>
      <span>Username: <span id="username">-</span></span><br/>
      <span>Room: <span id="room">-</span></span><br/>
      <span>Online: <span id="online">0</span></span>
    </div>
    <div className="bar">
      <div className="bar_wraper">
        <img  src="../assets/img/bar_button.png" id="hit" />
        <img src="../assets/img/bar.png" id="bar" />
        <div className="bar_head_wrapper">
          <img src="../assets/img/bar_head.png" id="bar_head" />
        </div>
      </div>
    </div> 
  </div>
};

export default Game;