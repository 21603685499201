import React from 'react';
import ReactDOM from 'react-dom/client';
// import './assets/css/style.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {SocketContext, socket} from './context/socket';

import App from './App';
import Game from './components/game';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SocketContext.Provider value={socket}>
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<App />} />

        <Route path="/" element={<App />} />
        <Route path="game" element={<Game />} />
      </Routes>
    </BrowserRouter>
  </SocketContext.Provider>
);

